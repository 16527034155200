import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Footer from "../components/Footer";
import Nav from "../components/nav";
import Stats from "../components/Stats";
import Tabs from "../components/Tabs";
import Video from "../components/Video";
import Stepper from "../components/Stepper";
import TwoColumnsCards from "../components/TwoColumnsCards";
import CTA from "../components/CTA";

import partnerLogo from "../images/logo_partner.png";
import Hero from "../components/Hero";
import Logos from "../components/Logos";
import Feature from "../components/Feature";

import Salt1 from "../images/saltstack/salt1.png";
import Salt2 from "../images/saltstack/salt2.png";
import Salt3 from "../images/saltstack/salt3.png";
import Salt4 from "../images/saltstack/salt4.png";
import Salt5 from "../images/saltstack/salt5.png";
import Salt6 from "../images/saltstack/salt6.png";
import SaltComply from "../images/SaltStack_Comply_black.png";
import SaltProtect from "../images/SaltStack_Protect_black.png";
import SaltEnterprise from "../images/saltstack/saltstack_enterprise.png";

const Tab1 = {
  title:
    "Contrôle et optimisation pour les infrastructures onPremise, Cloud et hybride.",
  items: [
    {
      id: 1,
      title: "Orchestration auto-corrective",
      description:
        "Maintenir les systèmes critiques correctement configurés pour accroître la fiabilité et optimiser l'utilisation des ressources. SaltStack est capable de détecter immédiatement les événements déclenchés par des problèmes liés à la configuration du système, des intégrations multiples ... Le moteur SaltStack peut également gérer une orchestration complexe telle que les correctifs et les redémarrages de système en plusieurs étapes, des flux inter-applications par exemple.",
      image: Salt1,
    },
    {
      id: 2,
      title: "Automatiser les taches informatiques",
      description:
        "SaltStack intègre des capacités d'orchestration intelligente pour gérer à la fois les workflows et l'automatisation du travail lui-même. Par exemple, SaltStack peut détecter une vulnérabilité, créer un ticket et avertir un agent opérationnel. SaltStack peut également effectuer une automatisation en plusieurs étapes pour corriger les systèmes vulnérables.",
      image: Salt2,
    },
    {
      id: 3,
      title: "Gestion simplifiée des infrastructures hybrides",
      description:
        "SaltStack est construit sur un puissant moteur d'automatisation piloté par les événements qui fournit au personnel IT un cadre de gestion simple pour l'ensemble de leur infrastructure. Quelque soit votre projet en cours, migration vers le cloud, environnement OnPremise ou déploiement de conteneurs, SaltStack facilite le contrôle de l'infrastructure sous-jacente pour vous permettre de construire une configuration réplicable au sein de votre DataCenter.",
      image: Salt3,
    },
  ],
};

const Tab2 = {
  title:
    "Contrôle et optimisation pour les infrastructures onPremise, Cloud et hybride.",
  items: [
    {
      id: 1,
      title: "Des systèmes informatiques déployés conformes et le restent.",
      description: `La première étape vers un environnement informatique conforme consiste à s'assurer que chaque nouveau système répond aux normes de sécurité. SaltStack permet aux équipes informatiques et de sécurité d'appliquer une approche simple de "politique de sécurité sous forme de code" à la création et au déploiement de chaque nouveau système, garantissant une conformité adéquate dès le jour 0 et au-delà.`,
      image: Salt4,
    },
    {
      id: 2,
      title: "Identifiez le problème. Réparez-le. Rapidement.",
      description:
        "La poursuite des non-conformité des systèmes existants peut ressembler à un cauchemar récurrent. SaltStack les recherche activement et propose des mesures correctives automatisées pour appliquer les politiques de sécurité définies - ce qui permet d'économiser des ressources, de renforcer la sécurité et de réduire les risques.",
      image: Salt5,
    },
    {
      id: 3,
      title: "Automatisation des tâches pour réduire les vulnérabilités.",
      description:
        "Les scans de sécurité peuvent signaler des avalanches de vulnérabilités que les équipes opérationnelles doivent traduire en tickets informatiques, puis enquêter, réparer, tester pour finalement faire un rapport d’intervention. SaltStack apporte la puissance de l'automatisation au SecOps en scannant les systèmes informatiques à la recherche de vulnérabilités, puis en fournissant des flux de travail prêts à l'emploi et automatisés.",
      image: Salt6,
    },
  ],
};
const SaltstackPage = ({ data: { saltstackPage } }) => {
  const { data } = saltstackPage;
  return (
    <Layout
      title={data.page_title.text}
      description={data.page_description.text}
      thumbnail={data.share_preview.url}
    >
      <Nav isDark></Nav>
      <header className="pt-24 sm:pt-24 md:pt-24 lg:pt-56 bg-gray-900 pb-24">
        <div className="mx-auto max-w-screen-xl">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
              <div>
                <h1 className="mt-4 mb-8 text-3xl tracking-tight leading-10 font-bold text-white sm:mt-5 sm:leading-none sm:text-5xl lg:mt-6 lg:text-4xl xl:text-5xl">
                  <span className="text-primary-400">Zen</span>Ops <br />
                  Une expertise unique sur les solutions SaltStack en France.
                </h1>
                  <a
                    href="#infra"
                    className="mt-4 text-base text-indigo-600 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl hover:underline animate-link-child-hover block"
                  >
                    <h2>
                      Automatisation d’Infrastructure
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18.634"
                        height="30.705"
                        viewBox="0 0 18.634 30.705"
                        fill="currentColor"
                        className="inline-block text-indigo-600 ml-2 transform  transition-all duration-500 ease-in-out h-3"
                      >
                        <path
                          id="icon_chevron"
                          d="M20.073,19.193,6.408,32.858a1.687,1.687,0,0,1-2.386,0L2.427,31.264a1.687,1.687,0,0,1,0-2.384L13.254,18,2.425,7.119a1.687,1.687,0,0,1,0-2.384L4.021,3.142a1.687,1.687,0,0,1,2.386,0L20.072,16.807A1.688,1.688,0,0,1,20.073,19.193Z"
                          transform="translate(-1.933 -2.648)"
                        />
                      </svg>
                    </h2>
                    <p className="text-indigo-200 text-sm">
                      Automatisation et orchestration informatique intelligente
                      pour les systèmes hybrides à l’échelle de l’entreprise.
                    </p>
                  </a>
                  <a
                    href="#secu"
                    className="mt-8 text-base text-indigo-600 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl  hover:underline animate-link-child-hover block"
                  >
                    <h2>
                      Automatisation de la Sécurité
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18.634"
                        height="30.705"
                        viewBox="0 0 18.634 30.705"
                        fill="currentColor"
                        className="inline-block text-indigo-600 ml-2 transform hover:translate-x-2 transition-all duration-500 ease-in-out h-3"
                      >
                        <path
                          id="icon_chevron"
                          d="M20.073,19.193,6.408,32.858a1.687,1.687,0,0,1-2.386,0L2.427,31.264a1.687,1.687,0,0,1,0-2.384L13.254,18,2.425,7.119a1.687,1.687,0,0,1,0-2.384L4.021,3.142a1.687,1.687,0,0,1,2.386,0L20.072,16.807A1.688,1.688,0,0,1,20.073,19.193Z"
                          transform="translate(-1.933 -2.648)"
                        />
                      </svg>
                    </h2>
                    <p className="text-indigo-200 text-sm">
                      Identifiez, corriger les vulnérabilités et mettre en place
                      une conformité permanente.
                    </p>
                  </a>

                <div className="mt-6 rounded-md shadow sm:flex-shrink-0 w-auto inline-block">
                  <div className="mt-6 rounded-md shadow sm:flex-shrink-0 w-auto inline-block">
                    <a
                      href="#contact"
                      className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring transform hover:-translate-y-1 transition duration-150 ease-in-out"
                    >
                      Demandez une démo !
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-span-6 flex justify-center items-center">
              <div className="px-4 py-8 sm:px-10">
                <svg
                  data-name="Calque 1"
                  viewBox="0 0 327.9 200.13"
                  style={{ width: "250px" }}
                >
                  <defs></defs>
                  <rect
                    className="fill-white"
                    x="52.58"
                    width="222.73"
                    height="83"
                    rx="12.86"
                  />
                  <rect
                    className="fill-white"
                    x="52.58"
                    y="117.13"
                    width="222.73"
                    height="83"
                    rx="12.86"
                  />
                  <g id="saltstack_logo" data-name="saltstack logo">
                    <path
                      id="Tracé_1165"
                      data-name="Tracé 1165"
                      d="M112.37,149.69l-7.18,20.54h4.39L111,166h7.4l1.33,4.22h4.55l-7.11-20.54Zm-.28,12.78,2.61-8.09,2.54,8.09Z"
                    />
                    <path
                      id="Tracé_1166"
                      data-name="Tracé 1166"
                      d="M103,160a8.8,8.8,0,0,0-3.47-1.49L96,157.69a12,12,0,0,1-2.67-.85,1.78,1.78,0,0,1-1-1.66V155H88.44v.49a5.07,5.07,0,0,0,2,4.45,13.38,13.38,0,0,0,4.41,1.57L97,162a9.14,9.14,0,0,1,2.79,1,1.74,1.74,0,0,1,.85,1.61A2.36,2.36,0,0,1,98.89,167a7.38,7.38,0,0,1-2.47.34A4.39,4.39,0,0,1,92.85,166a4.14,4.14,0,0,1-.77-2.1h-4a6.12,6.12,0,0,0,2.28,5,9.61,9.61,0,0,0,6.24,1.84,8.88,8.88,0,0,0,6-1.86,6,6,0,0,0,2.13-4.68A5.1,5.1,0,0,0,103,160Z"
                    />
                    <rect
                      id="Rectangle_335"
                      data-name="Rectangle 335"
                      x="85.01"
                      y="146.34"
                      width="5.32"
                      height="5.44"
                    />
                    <path
                      id="Tracé_1167"
                      data-name="Tracé 1167"
                      d="M164.33,153.32A3,3,0,0,1,166,156h4a6.12,6.12,0,0,0-2.38-5,8.81,8.81,0,0,0-5.49-1.72,12.3,12.3,0,0,0-2.77.29v3.83a4.68,4.68,0,0,1,2.48-.58A5.45,5.45,0,0,1,164.33,153.32Z"
                    />
                    <path
                      id="Tracé_1168"
                      data-name="Tracé 1168"
                      d="M130.34,149.69h-4.2v20.54h14.24v-3.69h-10Z"
                    />
                    <path
                      id="Tracé_1169"
                      data-name="Tracé 1169"
                      d="M243,149.69h-5.43l-7.78,8.46v-8.46h-4.12v20.54h4.13v-6.87l2-2.1,6.19,9h5.4l-8.67-12.1Z"
                    />
                    <path
                      id="Tracé_1170"
                      data-name="Tracé 1170"
                      d="M137.39,153.32h6v16.91h4.23V153.32h6v-3.63H137.39Z"
                    />
                    <path
                      id="Tracé_1171"
                      data-name="Tracé 1171"
                      d="M218.06,165.55a3.9,3.9,0,0,1-3.24,1.55,4.22,4.22,0,0,1-3.51-1.66,8.23,8.23,0,0,1-1.48-5.15,9.43,9.43,0,0,1,1.19-5.35,4.52,4.52,0,0,1,6.27-1.19,4.41,4.41,0,0,1,.58.47,6.18,6.18,0,0,1,1.09,2.21l4.19-.09a7.44,7.44,0,0,0-1.45-3.81c-1.6-2.23-4.06-3.31-7.41-3.24a8.2,8.2,0,0,0-6.12,2.67c-1.83,2-2.71,4.77-2.65,8.35.06,3.33.94,5.92,2.61,7.78a8.46,8.46,0,0,0,6.8,2.7,8,8,0,0,0,5.65-2.21,9.44,9.44,0,0,0,2.65-5.53l-4.18.09A6.45,6.45,0,0,1,218.06,165.55Z"
                    />
                    <path
                      id="Tracé_1172"
                      data-name="Tracé 1172"
                      d="M193.3,149.69l-7.17,20.54h4.4l1.36-4.22h7.41l1.32,4.22h4.56l-7.12-20.54ZM193,162.47l2.61-8.09,2.54,8.09Z"
                    />
                    <path
                      id="Tracé_1173"
                      data-name="Tracé 1173"
                      d="M168.83,160.16a9.08,9.08,0,0,0-3.48-1.5l-3.53-.85a11.53,11.53,0,0,1-2.67-.85,1.75,1.75,0,0,1-1-1.65v-.2h-3.89c0,.17,0,.33,0,.5a5.07,5.07,0,0,0,2,4.45,13.32,13.32,0,0,0,4.4,1.57l2.17.49a9.18,9.18,0,0,1,2.78,1,1.78,1.78,0,0,1,.89,1.58,2.39,2.39,0,0,1-1.8,2.38,7.62,7.62,0,0,1-2.46.34,4.38,4.38,0,0,1-3.58-1.27A4.16,4.16,0,0,1,158,164h-4a6.13,6.13,0,0,0,2.28,5,9.66,9.66,0,0,0,6.24,1.83,8.78,8.78,0,0,0,6-1.86,6,6,0,0,0,2.13-4.68A5.07,5.07,0,0,0,168.83,160.16Z"
                    />
                    <path
                      id="Tracé_1174"
                      data-name="Tracé 1174"
                      d="M188,149.69H171.68v3.63h6v16.91H182V153.32h6Z"
                    />
                    <path
                      id="Tracé_1175"
                      data-name="Tracé 1175"
                      d="M99.11,153.13a3,3,0,0,1,1.62,2.64h4a6.13,6.13,0,0,0-2.37-5,8.82,8.82,0,0,0-5.49-1.73,11.84,11.84,0,0,0-2.78.3v3.83a4.7,4.7,0,0,1,2.49-.58A5.4,5.4,0,0,1,99.11,153.13Z"
                    />
                    <path
                      id="Tracé_1176"
                      data-name="Tracé 1176"
                      d="M246.82,168.58a.53.53,0,0,0,.08-.32.73.73,0,0,0,0-.25.37.37,0,0,0-.13-.17.53.53,0,0,0-.21-.11.86.86,0,0,0-.28,0h-.65v1.88h.32v-.72h.36l.37.72H247l-.41-.78A.64.64,0,0,0,246.82,168.58Zm-.43,0h-.48V168h.33a.38.38,0,0,1,.15,0,.24.24,0,0,1,.11.06.61.61,0,0,1,.07.1v.26a.24.24,0,0,1-.07.12.27.27,0,0,1-.1.06Z"
                    />
                    <path
                      id="Tracé_1177"
                      data-name="Tracé 1177"
                      d="M246.22,167.05a1.56,1.56,0,0,0-1.44,1,1.62,1.62,0,0,0,.34,1.74,1.53,1.53,0,0,0,1.7.34,1.6,1.6,0,0,0-.6-3.06Zm.93,2.54a1.34,1.34,0,0,1-.93.4v0a1.29,1.29,0,0,1-1.2-.81,1.36,1.36,0,0,1,.28-1.45,1.27,1.27,0,0,1,1.41-.29,1.33,1.33,0,0,1,.8,1.23,1.34,1.34,0,0,1-.36,1Z"
                    />
                  </g>
                  <g id="logo_zenops_large" data-name="logo zenops large">
                    <path
                      id="Tracé_1080"
                      data-name="Tracé 1080"
                      className="fill-primary-900"
                      d="M93.32,48.93,111,27.52H93.81V22.17h24.24v4.6L100.91,48.18h17.27v5.34H93.32Z"
                    />
                    <path
                      id="Tracé_1081"
                      data-name="Tracé 1081"
                      className="fill-primary-900"
                      d="M130.42,53.87a10.3,10.3,0,0,1-4-.75,9.12,9.12,0,0,1-5-5.11,9.65,9.65,0,0,1-.69-3.64,10.29,10.29,0,0,1,.67-3.72,9.19,9.19,0,0,1,1.94-3.08,9.46,9.46,0,0,1,3.09-2.1,10.21,10.21,0,0,1,4.08-.77,10,10,0,0,1,4.05.77,9.49,9.49,0,0,1,3,2.09,8.87,8.87,0,0,1,1.9,3,10.16,10.16,0,0,1,.66,3.61c0,.3,0,.6,0,.88a4.31,4.31,0,0,1-.09.71h-14.3a5.29,5.29,0,0,0,.53,1.94,4.73,4.73,0,0,0,2.61,2.37,4.8,4.8,0,0,0,1.76.31,5.38,5.38,0,0,0,2.67-.68A3.63,3.63,0,0,0,135,47.9L139,49a8.37,8.37,0,0,1-3.27,3.48A10,10,0,0,1,130.42,53.87Zm4.8-11.15a4.89,4.89,0,0,0-1.54-3.34,4.94,4.94,0,0,0-5.08-.92,4.56,4.56,0,0,0-1.47.94,4.78,4.78,0,0,0-1,1.44,5.12,5.12,0,0,0-.48,1.88Z"
                    />
                    <path
                      id="Tracé_1082"
                      data-name="Tracé 1082"
                      className="fill-primary-900"
                      d="M160.19,53.52h-4.73V43.14a5.37,5.37,0,0,0-.78-3.25,2.55,2.55,0,0,0-2.15-1,4,4,0,0,0-1.45.29,5.31,5.31,0,0,0-1.39.79,6.25,6.25,0,0,0-1.19,1.22,5,5,0,0,0-.78,1.55V53.52H143V35h4.28v3.43a7,7,0,0,1,3-2.76,9.49,9.49,0,0,1,4.38-1,5.47,5.47,0,0,1,2.83.63A4.64,4.64,0,0,1,159.13,37a6.71,6.71,0,0,1,.83,2.33,14.75,14.75,0,0,1,.23,2.65Z"
                    />
                    <path
                      id="Tracé_1083"
                      data-name="Tracé 1083"
                      className="fill-gray-900"
                      d="M178.82,53.74a14.19,14.19,0,0,1-6.25-1.35,15.36,15.36,0,0,1-4.82-3.57,16.64,16.64,0,0,1-4.21-11,15.69,15.69,0,0,1,1.17-6,16.74,16.74,0,0,1,3.22-5.07,15.43,15.43,0,0,1,4.88-3.51A14.45,14.45,0,0,1,179,22a13.59,13.59,0,0,1,6.2,1.39A15.38,15.38,0,0,1,189.94,27,16.65,16.65,0,0,1,193,32.11,16.13,16.13,0,0,1,189.81,49,15.18,15.18,0,0,1,185,52.44,14.4,14.4,0,0,1,178.82,53.74Zm-9.1-15.9a12.72,12.72,0,0,0,.62,3.93,10.28,10.28,0,0,0,1.79,3.36,8.59,8.59,0,0,0,10.64,2.32A8.89,8.89,0,0,0,185.64,45a10.69,10.69,0,0,0,1.76-3.38,12.35,12.35,0,0,0,.6-3.82,12.15,12.15,0,0,0-.64-3.92,10.72,10.72,0,0,0-1.81-3.36,8.72,8.72,0,0,0-2.87-2.32,9,9,0,0,0-7.75,0,8.83,8.83,0,0,0-2.87,2.39A10.4,10.4,0,0,0,170.32,34,12.45,12.45,0,0,0,169.72,37.84Z"
                    />
                    <path
                      id="Tracé_1084"
                      data-name="Tracé 1084"
                      className="fill-dark"
                      d="M208.79,53.87a7.19,7.19,0,0,1-3.81-1,6.93,6.93,0,0,1-2.55-2.63V61H197.7V35h4.14v3.18a7.31,7.31,0,0,1,6.39-3.5,7.94,7.94,0,0,1,3.46.76,8.76,8.76,0,0,1,2.74,2,9.63,9.63,0,0,1,1.8,3,10.32,10.32,0,0,1,.65,3.69,11.55,11.55,0,0,1-.6,3.78,9.68,9.68,0,0,1-1.68,3.07A7.72,7.72,0,0,1,212,53.13,7,7,0,0,1,208.79,53.87Zm-1.58-4a4.31,4.31,0,0,0,2-.46,4.7,4.7,0,0,0,1.53-1.24,5.66,5.66,0,0,0,1-1.8,6.13,6.13,0,0,0,.35-2.12,5.94,5.94,0,0,0-.38-2.17,5.46,5.46,0,0,0-1.08-1.75A5,5,0,0,0,209,39.15a4.9,4.9,0,0,0-2-.43,4.77,4.77,0,0,0-2.69.89,5.54,5.54,0,0,0-1.07,1,4.1,4.1,0,0,0-.73,1.24v4.34a6.45,6.45,0,0,0,1.94,2.64,4.48,4.48,0,0,0,2.83,1Z"
                    />
                    <path
                      id="Tracé_1085"
                      data-name="Tracé 1085"
                      className="fill-gray-900"
                      d="M227.09,53.87a14.42,14.42,0,0,1-4.56-.74A11.26,11.26,0,0,1,218.72,51l1.76-3a14.81,14.81,0,0,0,3.31,1.82,8.92,8.92,0,0,0,3.19.62,4,4,0,0,0,2.23-.53,1.7,1.7,0,0,0,.81-1.52,1.51,1.51,0,0,0-1-1.45,20.94,20.94,0,0,0-3.1-1c-1.2-.33-2.23-.65-3.07-1a7.68,7.68,0,0,1-2.06-1,3.63,3.63,0,0,1-1.12-1.34,4.41,4.41,0,0,1-.36-1.82,5.91,5.91,0,0,1,.55-2.55,5.62,5.62,0,0,1,1.54-1.92,7,7,0,0,1,2.31-1.22,9.34,9.34,0,0,1,2.88-.42,12.31,12.31,0,0,1,3.9.6,9.42,9.42,0,0,1,3.34,1.94L232,40.06a11,11,0,0,0-2.73-1.55,7.73,7.73,0,0,0-2.64-.5,3.83,3.83,0,0,0-2,.5,1.7,1.7,0,0,0-.81,1.59,1.54,1.54,0,0,0,.19.81,1.75,1.75,0,0,0,.62.56,5.73,5.73,0,0,0,1.11.48c.46.15,1,.31,1.72.48,1.26.33,2.36.66,3.26,1a9,9,0,0,1,2.23,1.13A4.07,4.07,0,0,1,234.17,46a4.59,4.59,0,0,1,.4,2,5.14,5.14,0,0,1-2,4.29A8.64,8.64,0,0,1,227.09,53.87Z"
                    />
                  </g>
                  <path
                    className="fill-none path stroke-indigo"
                    d="M52.58,160C23.82,160,.5,132.77.5,99.23S23.82,38.51,52.58,38.51"
                  />
                  <path
                    className="fill-none path stroke-primary"
                    d="M275.32,38.51c28.76,0,52.08,27.19,52.08,60.72S304.08,160,275.32,160"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="py-12 bg-gray-100 pt-24" id="infra">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 ">
          <h3
            className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-indigo-600 sm:leading-10 py-2 z-10 bg-gray-100 lg:text-center"
            style={{ position: "sticky", top: "50px" }}
          >
            Automatisation d’Infrastructure
          </h3>
          <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto lg:text-center">
            Automatisation et orchestration informatique intelligente pour les
            systèmes hybrides à l’échelle de l’entreprise.
          </p>

          {/* Vidéos */}
          <Video
            src={"451138336"}
            title={
              "Une automatisation efficace et orchestration pour l’ensemble du département informatique."
            }
            image={[{ src: SaltEnterprise, alt: " " }]}
          />

          {/* Tabs */}
          <Tabs color="indigo" data={Tab1} />
        </div>
      </div>

      {/* CTA Banner  */}
      <div className="bg-gray-900 text-white">
        <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 flex flex-col items-start">
          <h2 className="text-3xl leading-9 font-extrabold tracking-tight  sm:text-4xl sm:leading-10">
            Vous voulez en savoir plus ?
            <br className="inline sm:inline" />
            <span className="text-primary-600" id="newsletter-headline">
              Plannifiez une démo avec un de nos experts SaltStack !
            </span>
          </h2>
          <div className="mt-6 rounded-md shadow sm:flex-shrink-0 w-auto inline-block">
            <a
              href="#contact"
              className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring transition duration-150 ease-in-out"
            >
              Demandez une démo !
            </a>
          </div>
        </div>
      </div>

      <div className="py-12 pt-24 bg-gray-100" id="secu">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 ">
          <h3
            className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-indigo-600 bg-gray-100 sm:leading-10 py-2 z-10 lg:text-center"
            style={{ position: "sticky", top: "50px" }}
          >
            Automatisation de la Sécurité
          </h3>
          <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto lg:text-center">
            SaltStack SecOps exploite une technologie d'automatisation pilotée
            par les événements pour assurer la conformité des systèmes
            informatiques et la correction des vulnérabilités.
          </p>

          {/* Vidéos */}
          <Video
            src={"469641333"}
            title={
              "Une automatisation efficace et orchestration pour l’ensemble du département informatique."
            }
            image={[
              { src: SaltComply, alt: "Continuous compliance" },
              { src: SaltProtect, alt: "Active vulnerability remediation" },
            ]}
          />

          {/* Tabs */}
          <Tabs color="indigo" data={Tab2} />
        </div>
      </div>

      {/* CTA Banner  */}
      <div className="bg-gray-900 text-white">
        <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 flex flex-col items-start">
          <h2 className="text-3xl leading-9 font-extrabold tracking-tight  sm:text-4xl sm:leading-10">
            Vous voulez en savoir plus ?
            <br className="inline sm:inline" />
            <span className="text-primary-600">
              Plannifiez une démo avec un de nos experts SaltStack !
            </span>
          </h2>
          <div className="mt-6 rounded-md shadow sm:flex-shrink-0 w-auto inline-block">
            <a
              href="#contact"
              className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring transition duration-150 ease-in-out"
            >
              Demandez une démo !
            </a>
          </div>
        </div>
      </div>

      {/* Stepper */}
      <div className="bg-gray-100 pt-8">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 ">
          <h3
            className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-indigo-600 bg-gray-100 sm:leading-10 py-2 z-10 lg:text-center"
            style={{ position: "sticky", top: "50px" }}
          >
            De Salt Open à SaltStack Entreprise
          </h3>
          <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto lg:text-center">
            Passer à l’échelle supérieure avec SaltStack Enterprise pour
            garantir conformité, orchestration et automatisation. <br />{" "}
            SaltStack Enterprise s’appuie sur Salt Automation Engine pour
            permettre à vos équipes Ops une maitrise complète de vos
            environnements de production.
          </p>

          <Stepper />
        </div>
      </div>

      <div className="py-12 bg-gray-900 " id="contact">
        <div className="max-w-l mx-auto lg:max-w-screen-xl ">
          {/* <img
            className="mx-auto  rounded shadow"
            src="https://www.hubspot.fr/hs-fs/hubfs/assets/hubspot.com/parrotdad/feature-pages/sales-meetings-1.png?width=1025&name=sales-meetings-1.png"
          /> */}
          <div className="max-w-3xl mx-auto lg:max-w-none">
            <p className="py-8 mt-2 text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none text-center">
              Plannifiez une démo avec nos consultants SaltStack
            </p>
          </div>
          <div
            className="meetings-iframe-container"
            data-src="https://events.zenops.fr/meetings/arnaud59/speed-dating-consulting-saltstack?embed=true"
          >
            {" "}
            <iframe
              src="https://events.zenops.fr/meetings/arnaud59/speed-dating-consulting-saltstack?embed=true"
              style={{
                minWidth: "312px",
                minHeight: "516px",
                height: "756px",
                border: "medium none",
              }}
              width="100%"
            ></iframe>
          </div>{" "}
          <script
            type="text/javascript"
            src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
          ></script>
        </div>
      </div>
      <Footer title={data.page_title.text} />
    </Layout>
  );
};

export default SaltstackPage;

export const pageQuery = graphql`
  query SaltstackQuery {
    saltstackPage: prismicSaltstackPage {
      data {
        page_title {
          text
        }
        page_description {
          text
        }
        share_preview {
          url
        }
      }
    }
  }
`;

// export const pageQuery = graphql`
//   query IndexQuery {
//     homePage: prismicHomepage {
//       data {
